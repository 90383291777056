.app {
  text-align: center;
}

.app_header {
  /*background-color: #282c34;*/
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

/**
共通
 */
.title {
  padding: 20px;
  font-weight: bold;
  font-size: 30px;
}

.text_decoration_none {
  text-decoration: none;
  color: black;
}

.button {
  background: white;
  border: solid 1px;
  width: 20%;
  height: 50px;
}

/**
ログイン画面
 */
.login_wrap {
  width: 100%;
  text-align: center;
  min-height: 90vh;
  position: relative;
}

.login_body {
  position: absolute;
  left: 0;
  right: 0;
}

.login_form_wrap {
  padding: 0 25px;
  margin: 0 auto;
  display: inline-block;
  text-align: left;
  width: 500px;
  box-sizing: border-box;
}

.login_form {
  padding-bottom: 20px;
}

.login_form_input {
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 20px;
}

.login_form_input_password {
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 20px;
  -webkit-text-security: disc;
}

.login_buttons_wrap {
  padding: 0 20px 20px 20px;
}

.login_button {
  color: black;
  width: 460px;
  margin-top: 20px;
  padding: 10px;
  background: white;
}

//スマホ表示
@media (max-width: 480px) {
  .login_wrap {
    width: 100%;
    text-align: left;
  }

  .login_body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .login_form_wrap {
    padding: 20px;
    margin: 0 auto;
    display: inline-block;
    text-align: left;
    width: 100%;
  }

  .login_button {
    color: black;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    background: white;
  }
}

/**
情報登録
 */
.registration_wrap {
  padding: 20px;
  text-align: left;
}

.registration_item_wrap {
  padding-bottom: 10px;
}

.registration_item {
  text-align: left;
  padding: 3px 10px;
  margin-top: 3px;
  color: darkgrey;
}

.registration_data {
  text-align: left;
  padding: 3px 10px;
  margin-top: 3px;
}

.registration_line {
  border-bottom: 1px solid;
  padding: 10px 0;
}

.registration_time_wrap {
  padding-top: 10px;
}

.registration_button_wrap {
  padding: 10px 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.registration_button_wrap2 {
  width: 27%;
  padding: 3%;
}

.registration_header {
  padding-top: 20px;
}

.registration_time_button {
  background: white;
  border: solid 1px;
  width: 100%;
  height: 50px;
}

.registration_wide_button {
  color: black;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background: white;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/**
履歴一覧画面
 */
.history_outline {
  margin-top: 30px;
  width: 100%;
  text-align: left;
  overflow: scroll;
}

.history_globalid_title {
  text-align: left;
  padding: 3px 10px;
  color: darkgrey;
}

.history_globalid {
  text-align: left;
  padding: 3px 10px;
}

.history_list_main {
  height: 63vh;
  border: solid 1px black;
  margin: 5px;
  overflow: scroll;
}

.history_date {
  border-top: solid 1px black;
  text-align: left;
  padding: 2px;
}

// 第一引数で基準となる画面幅のフォントサイズ、第二引数で基準となる画面幅を入力
@function get_vw($size, $viewport:480) {
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:8) {
  font-size: calc(#{get_vw($font_size)} + 2vmin);
}

.history_item {
  display: block;
  text-decoration: none;
  @include fz_vw();
}

.history_item:hover {
  background-color: #eee;
}

.history_time_place {
  @include fz_vw();
  padding: 2px;
  margin: 0;
}

.history_button {
  color: black;
  width: 100px;
  margin-top: 20px;
  padding: 10px;
  background: white;
}

.history_button_wrap {
  text-align: left;
  position: fixed;
  bottom: 10px;
}

/**
履歴詳細画面
 */
.history_detail_outline {
  text-align: left;
  width: 100%;
  overflow: scroll;
}

.history_detail_wrap {
  overflow: auto;
  margin-top: 30px;
}

.history_detail_title {
  text-align: left;
  padding: 3px 10px;
  margin-top: 3px;
  color: darkgrey;
}

.history_detail_value {
  text-align: left;
  padding: 3px 10px;
}

.history_button_left_wrap {
  width: 50%;
  text-align: left;
  position: fixed;
  bottom: 10px;
}

.history_button_right_wrap {
  width: 50%;
  text-align: Right;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.history_input_datetime {
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 20px;
  background-color: white;
}
